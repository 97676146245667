import React, { useContext } from 'react'
import { COLOR } from '@farewill/ui/tokens'
import { SplitContext } from '@splitsoftware/splitio-react'

import DefaultLayout from 'layouts/DefaultLayout'
import { PRODUCTS } from 'lib/products/constants'
import PROBATE_JSONLD from 'lib/json-ld/probate'
import JSONLD from 'components/JSONLD'
import { roundDownToNearestHundred } from 'lib/formatting/numbers'
import { useExperiment } from 'lib/hooks/useExperiment'
import {
  PROBATE_TELEPHONE_NUMBER_EXPERIMENT,
  TRUSTPILOT_RATING_OUT_OF_5,
  TRUSTPILOT_REVIEWS_TOTAL,
} from 'config'

import Content from '../../public/Apply/Content'

const Apply = (): React.ReactElement | null => {
  // ExptProbateBooking - Clean up this component after experiment is done
  const treatment = useExperiment('ExptProbateBooking')
  const { isReady } = useContext(SplitContext)
  const isExperiment = treatment === 'experiment_variant'

  return isReady ? (
    <>
      <JSONLD data={PROBATE_JSONLD} />

      <DefaultLayout
        title="Need Help With Probate? | Apply For Probate The Simpler Way"
        description={`Our award-winning team makes applying for probate quick, easy and affordable. Free phone support. Rated ${TRUSTPILOT_RATING_OUT_OF_5} out of 5 by over ${roundDownToNearestHundred(
          TRUSTPILOT_REVIEWS_TOTAL
        )} customers. Client Care Initiative of the Year 2020.`}
        product={PRODUCTS.PROBATE}
        headerColor={COLOR.WHITE}
        footerShowFwlsDisclaimer
        headerType="compact"
        footerType="compact"
        telephoneNumber={
          isExperiment ? PROBATE_TELEPHONE_NUMBER_EXPERIMENT : undefined
        }
        usePhoneNumberTracking={!isExperiment}
      >
        <Content ctaText="Get a quote in 5 minutes" />
      </DefaultLayout>
    </>
  ) : null
}

export default Apply
